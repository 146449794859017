import React from 'react';
import logo from '@img/logo.webp';
import { useLocation } from "react-router-dom";
import '@styles/404.scss';

const NotFound = () => {
	const location = useLocation();
    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);
	return (
		<div className='_404'>
			<img src={logo} alt="firulife-logo" onClick={() => selectPage("/")}/>
            <div className="mark">
                <div className='orange'>FIRU</div><div className='blue'>LIFE</div>
				<p className="text-404">Lo siento, la página no existe :(</p>
            </div>
		</div>
	);
}

export default NotFound;